import React, { useEffect, useState } from 'react';
import { Box, Card, Select } from '@deckee/deck-hand';
import { TripFilters } from '../../components/TripSearchControls/TripSearchControls';
import { TripStatus } from '../../lib/trip';
import { Group } from '../../interfaces/group';
import styled from 'styled-components';
import Close from '../../components/svg/close';
import useGroups from '../../hooks/use-groups';
import { HighestStatusLevel } from '../../interfaces/trip';
import GroupMultiSelector from '../../components/GroupMultiSelector/GroupMultiSelector';

const SelectedFilterWrapper = styled(Box)`
  flex-wrap: wrap;
`;

const FilterOption = styled(Box)`
  background-color: #ffede9;
  color: #ff5c39;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
`;

const HighestStatusFilterNames = {
  [HighestStatusLevel.Sar30]: 'SAR Incident',
  [HighestStatusLevel.Overdue]: 'Overdue',
  [HighestStatusLevel.DueNow]: 'Due',
};

type TripDropdownFilters = Pick<
  TripFilters,
  'status' | 'groups' | 'highestStatus'
>;

interface TripFiltersFeatureProps {
  hideStatusFilter?: boolean;
  hideHighestStatusFilter?: boolean;
  onFilterChange: (filters: TripDropdownFilters) => void;
}

const TripFiltersFeature = ({
  hideStatusFilter,
  hideHighestStatusFilter,
  onFilterChange,
}: TripFiltersFeatureProps) => {
  const [statusFilters, setStatusFilters] = useState<TripStatus[]>([]);

  const [highestStatusFilters, setHighestStatusFilters] = useState<
    TripDropdownFilters['highestStatus']
  >([]);

  const [selectedBaseGroups, setSelectedBaseGroups] = useState<Group['id'][]>(
    [],
  );

  const { groups: baseGroups } = useGroups({
    type: 'base',
    myGroups: true,
  });

  useEffect(() => {
    onFilterChange({
      status: statusFilters,
      groups: selectedBaseGroups,
      highestStatus: highestStatusFilters,
    });
  }, [statusFilters, selectedBaseGroups, highestStatusFilters, onFilterChange]);

  return (
    <Card>
      <Card.Heading size="large" level="2" mb="0">
        Filters
      </Card.Heading>

      <Card.Body>
        {!hideHighestStatusFilter && (
          <>
            <Card.Heading
              mt="0 !important"
              pb="10px !important"
              pl="0 !important"
              size="compact"
              level="3"
            >
              Highest status level
            </Card.Heading>
            <Select
              value=""
              onChange={(e) => {
                const selectedSet = new Set([
                  ...highestStatusFilters,
                  e.target.value,
                ]);
                return setHighestStatusFilters(
                  // Convert selectedSet to an array
                  Array.from(selectedSet).filter((status) => status),
                );
              }}
            >
              <Select.Option selected value=""></Select.Option>
              <Select.Option selected={false} value={HighestStatusLevel.Sar30}>
                SAR Incident
              </Select.Option>
              <Select.Option
                selected={false}
                value={HighestStatusLevel.Overdue}
              >
                Overdue
              </Select.Option>
              <Select.Option selected={false} value={HighestStatusLevel.DueNow}>
                Due
              </Select.Option>
            </Select>

            <SelectedFilterWrapper display="flex" pt="2">
              {highestStatusFilters.map((filterId) => {
                return (
                  <FilterOption
                    px="2"
                    py="1"
                    mr="2"
                    mt="2"
                    fontWeight="bold"
                    onClick={() => {
                      setHighestStatusFilters(
                        highestStatusFilters.filter((b) => b !== filterId),
                      );
                    }}
                  >
                    {HighestStatusFilterNames[filterId]} <Close size={14} />
                  </FilterOption>
                );
              })}
            </SelectedFilterWrapper>
          </>
        )}

        {!hideStatusFilter && (
          <>
            <Card.Heading
              mt="0 !important"
              pb="10px !important"
              pl="0 !important"
              size="compact"
              level="3"
            >
              Status
            </Card.Heading>
            <Select
              value=""
              onChange={(e) => {
                const selectedSet = new Set([...statusFilters, e.target.value]);
                return setStatusFilters(
                  // Convert selectedSet to an array
                  Array.from(selectedSet).filter((baseId) => baseId),
                );
              }}
            >
              <Select.Option selected value=""></Select.Option>
              <Select.Option selected={false} value={TripStatus.SAR30Incident}>
                SAR Incident
              </Select.Option>
              <Select.Option selected={false} value={TripStatus.Overdue15}>
                Overdue
              </Select.Option>
              <Select.Option selected={false} value={TripStatus.Due}>
                Due
              </Select.Option>
            </Select>

            <SelectedFilterWrapper display="flex" pt="2">
              {statusFilters.map((filterId) => {
                return (
                  <FilterOption
                    px="2"
                    py="1"
                    mr="2"
                    mt="2"
                    fontWeight="bold"
                    onClick={() => {
                      setStatusFilters(
                        statusFilters.filter((b) => b !== filterId),
                      );
                    }}
                  >
                    {filterId} <Close size={14} />
                  </FilterOption>
                );
              })}
            </SelectedFilterWrapper>
          </>
        )}

        <Card.Heading
          mt="0 !important"
          pb="10px !important"
          pl="0 !important"
          size="compact"
          level="3"
        >
          Groups
        </Card.Heading>

        <GroupMultiSelector
          groups={baseGroups}
          selectedGroupIds={selectedBaseGroups}
          onChange={setSelectedBaseGroups}
        />
      </Card.Body>
    </Card>
  );
};

export default TripFiltersFeature;
